import * as API from '../api';

export default class Survey {
    template_id : string;
    study_id? : number;
    created_by : string;
    is_grid : boolean;

    created_on : Date;

    data : any;
    associated_survey? : any;

    template_data? : any;
 
    constructor(data : any) {
        this.template_id = data["template"];
        this.study_id = data["study_id"];
        this.created_by = data["created_by"];
        this.created_on = new Date(data["created_on"]);
        this.is_grid = false;

        try {
            if (data["data"]["data"]) this.data = data["data"]["data"];
            else this.data = data["data"]

            if (typeof this.data === 'string') this.data = JSON.parse(this.data);
        } catch (e) {
            console.log("error parsing survey results", e);
            console.log("survey data:", this.data);
            this.data = data["data"];
        }

        try {
            if (data["associated_survey"]) this.associated_survey = data["associated_survey"];

            if (typeof this.associated_survey === 'string') this.associated_survey = JSON.parse(this.associated_survey);
        } catch (e) {
            console.log("error parsing associated survey results", e);
            console.log("associated survey data:", this.data);
        }
        
        if (data["template_data"]) {
            this.template_data = data["template_data"]["tasks"]
            this.is_grid = true;
        };

    }

    set_template = async () => {
        let response = await API.getSurveyTemplate(this.template_id);

        if (!response.ok) return false;

        let template = await response.json();
        this.template_data = template["data"]["tasks"];

        return true;
    }
}